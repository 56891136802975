import './labelingApp.css';
import React from 'react';
import {Button, Backdrop, CircularProgress} from '@mui/material';
import LinearProgressWithLabel from '../linearProgressWithLabel';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import axios from 'axios';
import {
  GET_DATA_URL,
  SEND_DATA_URL,
  GET_PROGRESS_URL,
  BACK_QUESTION_URL
} from '../../config';

import Hypotheses from '../hypotheses';
import History from '../history';
import DynamicInfo from '../dynamicInfo';


const theme = createTheme({
  palette: {
    primary: {
      main: '#2d8',
      contrastText: '#fff'
    },
    secondary: {
      main: '#000',
      contrastText: '#fff'
    },
  },
});

class LabelingApp extends React.Component {
  state = {
    data: undefined,
    showLastUtterance: false,
    isHistoryTrash: false,
    lastBestAnswer: undefined,
    error: undefined,
    progressBar: {progress: undefined, required: undefined},
    isSurveyRequired: false,
    isSurveyInfoPresent: undefined,
  }

  async getData() {
    axios.post(GET_DATA_URL, {
      user_name: this.props.user,
      password: this.props.password,
    })
      .then(res => res.data)
      .then(data => {
        this.setState({
          data, isHistoryTrash: false,
          lastBestAnswer: "",
          isSurveyRequired: false,
          isSurveyInfoPresent: false,
        })
      })
      .catch((e) => this.setState({error: e}));
  }

  async getProgress() {
    axios.post(GET_PROGRESS_URL, {
      user_name: this.props.user,
      password: this.props.password,
    })
      .then(res => res.data)
      .then(data => {
        this.setState({progressBar: {...data}})
      })
      .catch((e) => console.log(e));
  }

  async toPreviousQuestion() {
    this.setState({data: undefined});
    this.setState({isSurveyRequired: false, isSurveyInfoPresent: undefined,});
    this.setState({isHistoryTrash: false});
    axios.post(BACK_QUESTION_URL, {
      user_name: this.props.user,
      password: this.props.password,
    })
      .then(() => {
        this.getData();
        this.getProgress();
      })
      .catch((e) => console.log(e));
  }

  async sendData(payload) {
    this.setState({data: undefined});
    const {isHistoryTrash, isSurveyRequired, isSurveyInfoPresent} = this.state;
    axios.patch(SEND_DATA_URL, {
      labeler_name: this.props.user,
      user_name: this.props.user,
      password: this.props.password,
      labels: payload.labels,
      skipped: payload.skipped,
      is_surv_info_required: isSurveyRequired,
      is_surv_info_present: isSurveyInfoPresent,
    }).then(() => {
      this.setState({isSurveyRequired: false, isSurveyInfoPresent: undefined,});
      this.setState({isHistoryTrash: false});
      this.getData();
      this.getProgress();
    });
  }

  toggleLastHistoryUtterance(isLast) {
    this.setState({showLastUtterance: !this.state.showLastUtterance});
  }

  componentDidMount() {
    this.getData().then(() =>
      this.setState({isHistoryTrash: this.state.data?.was_bad_question})
    );
    this.getProgress();
  }

  render() {
    const {
      data,
      isHistoryTrash,
      error,
      progressBar: {progress = 0, required = 1},
      showLastUtterance
    } = this.state;
    return (
      <div className="labelingApp">
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={!data}
        >
          {!(error) ? <CircularProgress color="inherit"/> : <h2>{error}</h2>}
        </Backdrop>
        <ThemeProvider theme={theme}>
          <div className="logout-container">
            <Button
              variant="contained"
              onClick={() => this.props.handleLogOut()}
              sx={{float: 'right'}}
            >
              Log Out
            </Button>
          </div>
          {(data) ? (
            <div className="labelingApp-header">
              <div className='progress-bar-container'>
                <LinearProgressWithLabel value={progress} required={required}/>
              </div>
              <History
                history={data.history ? data.history : []}
                isHistoryTrash={isHistoryTrash}
                handleHistoryCheckbox={() => this.setState({isHistoryTrash: !isHistoryTrash})}
              />
              <DynamicInfo
                infos={data.dynamic ? data.dynamic : []}
                history={data.history ? data.history : []}
                isSurveyRequired={this.state.isSurveyRequired}
                isSurveyInfoPresent={this.state.isSurveyInfoPresent}
                handleDynamicInfoCheckboxes={(newState) => this.setState({...newState})}
              />
              <Hypotheses
                hypotheses={data ? data.hypotheses : []}
                handleSendData={(payload) => {
                  this.sendData(payload)
                }}
                oldBestAnswer={data.best_answer}
                handleToPreviousQuestion={() => this.toPreviousQuestion()}
                showSkip={this.props.user.includes("Aflac")}
              />
            </div>
          ) : ''}
        </ThemeProvider>
      </div>
    );
  }
}

export default LabelingApp;
